import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    fastFundingData: [],
    fastFundingAlertLoader: false,
    fastFundingDataExists:false,
    fastFundingCount: 0,
    defaultFastFundingFilters:{}
}


const lenderFastFunding = createSlice({
    name: "lenderFastFunding",
    initialState: defaultState,
    reducers: {
        setFastFundingData: (state, action) => {
            state = {
                ...state,
                fastFundingData: action.payload ? action.payload : state.fastFundingData,
            }
            return state
        },
        setFastFundingLoader: (state, action) => {
            state = {
                ...state,
                fastFundingAlertLoader: action.payload
            }
            return state
        },
        setFastFundingDataExists: (state, action) => { 
            state = {
               ...state,
                fastFundingDataExists: action.payload
            }
            return state
        },
        setDefaultFastFundingFilters: (state, action) => {
            state = {
                ...state,
                defaultFastFundingFilters: action.payload,
            }
            return state;
        },
        setFastFundingCount: (state, action) => {
            state = {
                ...state,
                fastFundingCount: action.payload
            }
            return state
        }
    }
})

export const { setFastFundingData, setFastFundingLoader, setFastFundingDataExists, setDefaultFastFundingFilters, setFastFundingCount } = lenderFastFunding.actions;

export default lenderFastFunding.reducer;