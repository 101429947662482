import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    overdueData: [],
    overDueAlertLoader: false,
    overdueCount: 0,
    overDueDataExists: false,
    defaultOverDueFilters:{}
}
const lenderTitleOverdue = createSlice({
    name: "lenderTitleOverdue",
    initialState: defaultState,
    reducers: {
        setOverdueData: (state, action) => {
            state = {
                ...state,
                overdueData: action.payload ? action.payload : state.overdueData
            }
            return state
        },
        setOverdueCount: (state, action) => {
            state = {
                ...state,
                overdueCount: action.payload ?? 0
            }
            return state
        },
        setOverdueLoader: (state, action) => {
            state = {
                ...state,
                overDueAlertLoader: action.payload
            }
            return state
        },
        setOverDueDataExists: (state, action) => {
            state = {
                ...state,
                overDueDataExists: action.payload
            }
            return state
        },
        setDefaultOverDueFilters: (state, action) => {
            state = {
                ...state,
                defaultOverDueFilters: action.payload,
            }
            return state;
        }
    }
})


export const { setOverdueData, setOverdueCount, setOverdueLoader, setOverDueDataExists,setDefaultOverDueFilters } = lenderTitleOverdue.actions

export default lenderTitleOverdue.reducer;