import { createAction, createSlice, current } from "@reduxjs/toolkit";

const defaultState = localStorage.getItem('token') ?
    {
        lenderBanners: [],
        windowHeight: window.innerHeight,
        isUserAuthenticated: true,
        isMydealers_API_Status: false,
        isLoaded: true,
        isSopraDealersLoaded: false,
        filterObject: {},
        token: localStorage.getItem('token'),
        expire_token: false,
        userDetails: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
        userMenu: [],
        dashboardRedirect: {
            currentTab: '',
            isRedirect: false
        },
        auctionDashboardRedirect: {
            currentTab: ''
        },
        lenderSelectedDealerId: localStorage.getItem('multiDealerId') ? localStorage.getItem('multiDealerId') : '',
        lenderSelectedDealerName: '',
        lenderSelectedDealerDetails: {},
        lenderSelectedSupplierId: localStorage.getItem('multiSupplierId') ? localStorage.getItem('multiSupplierId') : '',
        lenderSelectedSupplierName: '',
        lenderSelectedSupplierDetails: localStorage.getItem('multiSupplierId') && Object.keys(localStorage.getItem('multiSupplierDetails'))?.length > 0 ? JSON.parse(localStorage.getItem('multiSupplierDetails')) : {},
        lenderSelectedSupplierReference: '',
        supplierAssetTypes: [],
        documentTypes: [],
        partnerConfig: {},
        exportDocBody: ``,
        masterData: {
            states: [],
            LoanTypes: {},
            dealersList: [],
            suppliersList: [],
            commonFilters: {},
            dealerFeatures: {},
            holidayDates: [],
            sampleBankDocuments: []
        },
        partnerDetails: localStorage.getItem('partnerDetails') ? JSON.parse(localStorage.getItem('partnerDetails')) : {},
        partnerLoader: false,
        isMakeApiRefreshStatus: false,
        isServiceRefresh: false,
        masterMenuData: [],
        noteTagData: [],
        dealerRiskStatusList: [],
        showConfirmationModal: false,
        dealerTagsFieldsdata: [],
        isDealerTagsLoaded: false,
        assetTagsFieldsdata: [],
        topTenLists: [],
        isAssetTagsLoaded: false,
        actionsData: [],
        masterActionsData: [],
        commonLoader: false,
        notificationData: [],
        hideNotificationIcon: false,
        statusList: [],
        isNotificationInProgress: false,
        riskDashBoardTotalCount: 0,
        defaultPaginationData: [],
        globalPaginationData: {},
        activeTab: '',
        rolesListData: [],
        globalActionLoader: false,
        menuRoutes: [],
        getMenuApiCall: false,
        backToSuplierData: {},
        defaultFilter: {},
        isRedirectToSupplier: false,
        isRedirectToSupplierApproved: false,
        isRedirectToSupplierInventory: false,
        shortCutFilters: {},
        auctionInventoryVIn: ''
    } :
    {
        lenderBanners: [],
        windowHeight: window.innerHeight,
        isUserAuthenticated: false,
        isMydealers_API_Status: false,
        isLoaded: true,
        isSopraDealersLoaded: false,
        token: '',
        expire_token: false,
        userDetails: '',
        userMenu: [],
        dashboardRedirect: {
            currentTab: 'myfloorplan',
            isRedirect: false
        },
        auctionDashboardRedirect: {
            currentTab: 'mydealers'
        },
        lenderSelectedDealerId: '',
        lenderSelectedDealerName: '',
        lenderSelectedDealerDetails: {},
        lenderSelectedSupplierId: '',
        lenderSelectedSupplierName: '',
        lenderSelectedSupplierDetails: {},
        lenderSelectedSupplierReference: '',
        supplierAssetTypes: [],
        documentTypes: [],
        partnerConfig: {},
        exportDocBody: ``,
        masterData: {
            states: [],
            LoanTypes: {},
            dealersList: [],
            commonFilters: {},
            suppliersList: [],
            dealerFeatures: {},
            holidayDates: [],
            sampleBankDocuments: []
        },
        partnerDetails: {},
        partnerLoader: false,
        isMakeApiRefreshStatus: false,
        isServiceRefresh: false,
        masterMenuData: [],
        noteTagData: [],
        dealerRiskStatusList: [],
        showConfirmationModal: false,
        dealerTagsFieldsdata: [],
        isDealerTagsLoaded: false,
        assetTagsFieldsdata: [],
        isAssetTagsLoaded: false,
        topTenLists: [],
        isAssetTagsLoaded: false,
        pastPaymentAdvanceFilter: {},
        paymentsInProgressAdvanceFilter: {},
        upcomingPaymentsAdvanceFilter: {},
        notificationData: [],
        hideNotificationIcon: false,
        statusList: [],
        menuRoutes: []
    }

const sharedReducer = createSlice({
    name: "Shared",
    initialState: defaultState,
    reducers: {
        getWindowHeight: (state, action) => {
            state = {
                ...state,
                windowHeight: action.payload,
            }
            return state
        },
        loginData: (state, action) => {
            state = {
                ...state,
                isUserAuthenticated: true,
                userDetails: action.payload,
                dashboardRedirect: {
                    currentTab: 'myfloorplan',
                    isRedirect: false
                }
            }
            return state
        },
        upDateMenu: (state, action) => {
            state = {
                ...state,
                userMenu: action.payload,
            }
            return state;
        },
        setPatnerDetails: (state, action) => {
            state = {
                ...state,
                partnerDetails: action.payload
            }
            return state;
        },
        setDocumentTypes: (state, action) => {
            state = {
                ...state,
                documentTypes: action.payload,
            }
            return state;
        },
        setPartnerConfig: (state, action) => {
            state = {
                ...state,
                partnerConfig: action.payload,
            }
            return state;
        },
        setExportDocBody: (state, action) => {
            state = {
                ...state,
                exportDocBody: action.payload,
            }
            return state;
        },
        logOut: (state, action) => {
            state = {
                ...state,
                isUserAuthenticated: false,
                token: '',
                userDetails: '',
                dashboardRedirect: {
                    currentTab: 'myfloorplan',
                    isRedirect: false
                },
                auctionDashboardRedirect: {
                    currentTab: 'mydealers'
                },
                lenderSelectedDealerId: '',
                lenderSelectedDealerName: '',
                lenderSelectedDealerDetails: {},
                lenderSelectedSupplierId: '',
                lenderSelectedSupplierName: '',
                lenderSelectedSupplierDetails: {},
                lenderSelectedSupplierReference: '',
            }
            return state;
        },
        showLoader: (state, action) => {
            state = {
                ...state,
                isLoaded: false
            }
            return state;
        },
        hideLoader: (state, action) => {
            state = {
                ...state,
                isLoaded: true
            }
            return state;
        },
        isSopraDealersLoaded: (state, action) => {
            state = {
                ...state,
                isSopraDealersLoaded: action.payload
            }
            return state;
        },
        changeTab: (state, action) => {
            state = {
                ...state,
                dashboardRedirect: {
                    currentTab: action.payload.tab,
                    isRedirect: action.payload.isRedirect
                }
            }
            return state;
        },
        setFilterObject: (state, action) => {
            state = {
                ...state,
                filterObject: action.payload
            }
            return state
        },
        setToken: (state, action) => {
            state = {
                ...state,
                token: action.payload
            }
            return state;
        },
        tokenExpire: (state, action) => {
            state = {
                ...state,
                expire_token: action.payload
            }
            return state;
        },
        setDealerId: (state, action) => {
            state = {
                ...state,
                lenderSelectedDealerId: action.payload.dealerId,
                lenderSelectedDealerName: action.payload.dealerName,
                dashboardRedirect: {
                    currentTab: 'myfloorplan',
                    isRedirect: false
                },
                lenderSelectedDealerDetails: action.payload.dealerDetails ? action.payload.dealerDetails : {}
            }
            return state;
        },
        removeLenderSelectedDealerId: (state, action) => {
            state = {
                ...state,
                lenderSelectedDealerId: '',
                lenderSelectedDealerDetails: {},
                lenderSelectedDealerName: ''
            }
            return state;
        },
        setSupplierId: (state, action) => {
            state = {
                ...state,
                lenderSelectedSupplierId: action.payload.supplierID,
                lenderSelectedSupplierName: action.payload.supplierName,
                lenderSelectedSupplierReference: action.payload.supplierReference,
                lenderSelectedSupplierDetails: action.payload.supplierDetails
            }
            return state;
        },
        removeLenderSelectedSupplierId: (state, action) => {
            state = {
                ...state,
                lenderSelectedSupplierId: '',
                lenderSelectedSupplierDetails: {},
                lenderSelectedSupplierName: '',
                lenderSelectedSupplierReference: ''
            }
            return state;
        },
        changeAuctionTab: (state, action) => {
            state = {
                ...state,
                auctionDashboardRedirect: {
                    currentTab: action.payload
                },
            }
            return state;
        },
        setStates: (state, action) => {
            state = {
                ...state,
                masterData: {
                    ...state.masterData,
                    states: action.payload
                }
            }
            return state;
        },
        setLoanTypes: (state, action) => {
            state = {
                ...state,
                masterData: {
                    ...state.masterData,
                    LoanTypes: action.payload
                }
            }
            return state;
        },
        setHoliDays: (state, action) => {
            state = {
                ...state,
                masterData: {
                    ...state.masterData,
                    holidayDates: action.payload
                }
            }
            return state;
        },
        setSopraDealerList: (state, action) => {
            state = {
                ...state,
                masterData: {
                    ...state.masterData,
                    dealersList: action.payload
                }
            }
            return state;
        },
        setSopraSuppliersList: (state, action) => {
            state = {
                ...state,
                masterData: {
                    ...state.masterData,
                    suppliersList: action.payload
                }
            }
            return state;
        },
        setCommonAdvancedFiltersList: (state, action) => {
            state = {
                ...state,
                masterData: {
                    ...state.masterData,
                    commonFilters: action.payload
                }
            }
            return state;
        },
        setDealerFeatures: (state, action) => {
            state = {
                ...state,
                masterData: {
                    ...state.masterData,
                    dealerFeatures: action.payload
                }
            }
            return state;
        },
        myDealersApiStatus: (state, action) => {
            state = {
                ...state,
                isMydealers_API_Status: action.payload,
            }
            return state;
        },
        setApiRefresh: (state, action) => {
            state = {
                ...state,
                isMakeApiRefreshStatus: action.payload,
            }
            return state;
        },
        setSupplierAssetTypes: (state, action) => {
            state = {
                ...state,
                supplierAssetTypes: action.payload,
            }
            return state;
        },
        setLenderBanner: (state, action) => {
            state = {
                ...state,
                lenderBanners: action.payload,
            }
            return state;
        },
        setServicingApiRefresh: (state, action) => {
            state = {
                ...state,
                isServiceRefresh: action.payload
            }
            return state;
        },

        updateMasterMenuById: (state, action) => {
            state = {
                ...state,
                masterMenuData: action.payload
            }
            return state;
        },
        setNoteTagData: (state, action) => {
            state = {
                ...state,
                noteTagData: action.payload
            }
            return state;
        },
        setRiskStatusListDealers: (state, action) => {
            state = {
                ...state,
                dealerRiskStatusList: action.payload
            }
            return state
        },
        setTopTenLists: (state, action) => {
            state = {
                ...state,
                topTenLists: action.payload
            }
            return state
        },
        setConformationModal: (state, action) => {
            state = {
                ...state,
                showConfirmationModal: action.payload
            }
            return state
        },
        setTagFields: (state, action) => {
            state = {
                ...state,
                dealerTagsFieldsdata: action.payload.dealerTagsData && action.payload.dealerTagsData.length > 0 ? action.payload.dealerTagsData : state.dealerTagsFieldsdata,
                assetTagsFieldsdata: action.payload.assetTagsData && action.payload.assetTagsData.length > 0 ? action.payload.assetTagsData : state.assetTagsFieldsdata,
                isDealerTagsLoaded: action.payload.isDealerTagsLoaded ? action.payload.isDealerTagsLoaded : state.isDealerTagsLoaded,
                isAssetTagsLoaded: action.payload.isAssetTagsLoaded ? action.payload.isAssetTagsLoaded : state.isAssetTagsLoaded,
            }
            return state
        },
        setCsvData: (state, action) => {
            state = {
                ...state,
                notificationData: action.payload
            }
            return state
        },
        setHideNotificationIcon: (state, action) => {
            state = {
                ...state,
                hideNotificationIcon: action.payload
            }
            return state
        },
        setStatusList: (state, action) => {
            state = {
                ...state,
                statusList: action.payload,
            }
            return state;
        },
        setIsNotificationInProgress: (state, action) => {
            state = {
                ...state,
                isNotificationInProgress: action.payload,
            }
            return state;
        },
        setPastPaymentAdvanceFilter: (state, action) => {
            state = {
                ...state,
                pastPaymentAdvanceFilter: action.payload,
            }
            return state;
        },
        setPaymentsInProgressAdvanceFilter: (state, action) => {
            state = {
                ...state,
                paymentsInProgressAdvanceFilter: action.payload,
            }
            return state;
        },
        setUpcomingPaymentsAdvanceFilter: (state, action) => {
            state = {
                ...state,
                upcomingPaymentsAdvanceFilter: action.payload,
            }
            return state;
        },
        setSampleBankDocuments: (state, action) => {
            state = {
                ...state,
                masterData: {
                    ...state.masterData,
                    sampleBankDocuments: action.payload
                }
            }
            return state
        },
        setDefaultPaginationData: (state, action) => {
            state = {
                ...state,
                defaultPaginationData: action.payload
            }
            return state
        },
        setDynamicPaginationData: (state, action) => {
            state.globalPaginationData = state.globalPaginationData || {};
            if (!(action?.payload?.key in state?.globalPaginationData)) {
                state.globalPaginationData[action.payload.key] = action.payload.paginationData
            } else {
                if (action.payload?.innerKey && action.payload?.updateData) {
                    state.globalPaginationData[action.payload.key][action.payload.innerKey] = action.payload.updateData;
                }
                if (action.payload?.innerKey2 && action.payload?.updateVehicleLimit) {
                    state.globalPaginationData[action.payload.key][action.payload.innerKey2] = action.payload.updateVehicleLimit;
                }
            }
        },
        setActiveTab: (state, action) => {
            state = {
                ...state,
                activeTab: action.payload
            }
            return state
        },
        setActionsData: (state, action) => {
            state = {
                ...state,
                actionsData: action.payload
            }
            return state
        },
        setCommonLoader: (state, action) => {
            state = {
                ...state,
                commonLoader: action.payload
            }
            return state
        },
        setMasterActionsData: (state, action) => {
            state = {
                ...state,
                masterActionsData: action.payload
            }
            return state
        },
        setRolesListsData: (state, action) => {
            state = {
                ...state,
                rolesListData: action.payload
            }
            return state
        },
        setGlobalActionLoader: (state, action) => {
            state = {
                ...state,
                globalActionLoader: action.payload
            }
            return state
        },
        setMenuRoutes: (state, action) => {
            state = {
                ...state,
                menuRoutes: action.payload
            }
            return state
        },
        setGetMenuApiCall: (state, action) => {
            state = {
                ...state,
                getMenuApiCall: action.payload
            }
            return state
        },
        setBackToSuplierData: (state, action) => {
            state = {
                ...state,
                backToSuplierData: {
                    ...state.backToSuplierData,
                    [action.payload.key]: action.payload.value
                }
            }
            return state
        },
        setDefaultFilter: (state, action) => {
            state = {
                ...state,
                defaultFilter: {
                    ...state.defaultFilter,
                    [action.payload.key]: action.payload.value
                }
            }
            return state
        },
        setIsRedirectToSupplier: (state, action) => {
            state = {
                ...state,
                isRedirectToSupplier: action.payload
            }
            return state
        },
        setIsRedirectToSupplierApproved: (state, action) => {
            state = {
                ...state,
                isRedirectToSupplierApproved: action.payload
            }
            return state
        },
        setIsRedirectToSupplierInventory: (state, action) => {
            state = {
                ...state,
                isRedirectToSupplierInventory: action.payload
            }
            return state
        },
        setShortCutFilters: (state, action) => {
            state = {
                ...state,
                shortCutFilters: action.payload
            }
            return state
        },
        setAuctionInventoryVIn: (state, action) => {
            state = {
                ...state,
                auctionInventoryVIn: action.payload
            }
            return state
        }
    }
})

export const { getWindowHeight, setLenderBanner, loginData, setLoanTypes, setDocumentTypes, setPartnerConfig, setExportDocBody, upDateMenu, tokenExpire, setPatnerDetails, logOut, showLoader, hideLoader, isSopraDealersLoaded, changeTab, setToken, setDealerId, removeLenderSelectedDealerId, setSupplierId, removeLenderSelectedSupplierId, changeAuctionTab, setStates, setHoliDays,
    setSopraDealerList, setDealerFeatures, setSupplierAssetTypes, myDealersApiStatus, setApiRefresh, setServicingApiRefresh, setNoteTagData, setRiskStatusListDealers, setConformationModal, setTagFields, setCsvData, setHideNotificationIcon, setStatusList, setIsNotificationInProgress, setSampleBankDocuments, setDefaultPaginationData, setDynamicPaginationData, setActiveTab, setTopTenLists, setPastPaymentAdvanceFilter, setPaymentsInProgressAdvanceFilter,
    setUpcomingPaymentsAdvanceFilter, setBackToSuplierData, setDefaultFilter, setIsRedirectToSupplierApproved, setIsRedirectToSupplierInventory, setActionsData, setSopraSuppliersList, setCommonAdvancedFiltersList, setCommonLoader, updateMasterMenuById, setMasterActionsData, setRolesListsData, setFilterObject, setGlobalActionLoader, setMenuRoutes, setGetMenuApiCall, setShortCutFilters, setAuctionInventoryVIn, setIsRedirectToSupplier } = sharedReducer.actions;

export default sharedReducer.reducer;