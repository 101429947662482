import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    auctionData: [],
    auctionAlertLoader: false,
    auctionDataExists: false,
    auctionCount: 0,
    defaultAuctionFilters: {}
}
const lenderTitleAuction = createSlice({
    name: "lenderTitleAuction",
    initialState: defaultState,
    reducers: {
        setAuctionAlertsData: (state, action) => {
            state = {
                ...state,
                auctionData: action.payload ? action.payload : state.auctionData
            }
            return state
        },
        setAuctionAlertsLoader: (state, action) => {
            state = {
                ...state,
                auctionAlertLoader: action.payload
            }
            return state
        },
        setAuctionDataExists: (state, action) => {
            state = {
                ...state,
                 auctionDataExists: action.payload
            }
            return state
        },
        setDefaultAuctionFilters: (state, action) => {
            state = {
                ...state,
                defaultAuctionFilters: action.payload
            }
            return state
        },
        setAuctionCount: (state, action) => {
            state = {
                ...state,
                auctionCount: action.payload
            }
            return state
        }
    }
})

export const { setAuctionAlertsData, setAuctionAlertsLoader, setAuctionDataExists, setDefaultAuctionFilters, setAuctionCount } = lenderTitleAuction.actions;

export default lenderTitleAuction.reducer;