import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    servicingCounts: {},
    failedTasks: [],
    tasksList: [],
    leverTasksList: [],
    tasksHistory: [],
    reportedSoldAndPaidInFull: [],
    releasePaymentsForTR: [],
    vaultedTitles: [],
    failedTasksLoader: false,
    tasksListLoader: false,
    tasksHistoryLoader: false,
    reportedSoldAndPaidInFullLoader: false,
    releasePaymentsForTRLoader: false,
    vaultedTitlesLoader: false,
    leverTasksListLoader: false,
    failedTasksIssuesList: [],
    tasksHistoryAdvancedFilters: {},
    initialTaskHistoryList: [],
    vinTasksHistorySearchText: '',
    inventorySearch: [],
    vinSearchTextForInventorySearch: '',
    inventorySearchLoader: false,
    inventoryCounts: {},
    bankHolidaysList: [],
    bankHolidys_loader: false,
    initialbankHolidays: [],
    tasksListDataExists: false,
    reportedSoldAndPaidInFullExists: false,
    releasePaymentsForTRExists: false,
    leverTasksListExists: false,
    bankHolidysDataExists: false, 
    // vaultedTitlesExists: false,
  
    // failedTasksExists: false,
    // failedTasksIssuesListExists: false,

}

const Servicing = createSlice({
    name: "Servicing",
    initialState: defaultState,
    reducers: {
        setServicingCounts: (state, action) => {
            state = {
                ...state,
                servicingCounts: action.payload
            }
            return state
        },
        setTasksListandCounts: (state, action) => {
            state = {
                ...state,
                tasksList: action.payload
            }
            return state;
        },
        setLeverTasksList: (state, action) => {
            state = {
                ...state,
                leverTasksList: action.payload
            }
            return state;
        },
        setFailedTasks: (state, action) => {
            state = {
                ...state,
                failedTasks: action.payload
            }
            return state;
        },
        tasksHistoryAdvancedFilters: (state, action) => {
            state = {
                ...state,
                tasksHistoryAdvancedFilters: action.payload
            }
            return state;
        },
        setInitialTasksHistory: (state, action) => {
            state = {
                ...state,
                initialTaskHistoryList: action.payload
            }
            return state;
        },
        setTasksHistoryvinText: (state, action) => {
            state = {
                ...state,
                vinTasksHistorySearchText: action.payload
            }
            return state;
        },
        setTasksHistory: (state, action) => {
            state = {
                ...state,
                tasksHistory: action.payload
            }
            return state;
        },
        set_Rsapif: (state, action) => {
            state = {
                ...state,
                reportedSoldAndPaidInFull: action.payload
            }
            return state;
        },
        set_RsapifCounts: (state, action) => {
            state = {
                ...state,
                rsapifCounts: action.payload
            }
            return state
        },
        set_Rpftr: (state, action) => {
            state = {
                ...state,
                releasePaymentsForTR: action.payload
            }
            return state;
        },
        set_RpftrCounts: (state, action) => {
            state = {
                ...state,
                rpftrCounts: action.payload
            }
            return state;
        },
        setVaultedTitles: (state, action) => {
            state = {
                ...state,
                vaultedTitles: action.payload
            }
            return state;
        },
        loaderTasksList: (state, action) => {
            state = {
                ...state,
                tasksListLoader: action.payload
            }
            return state;
        },
        loaderLeverTasksList: (state, action) => {
            state = {
                ...state,
                leverTasksListLoader: action.payload
            }
            return state;
        },
        loaderFailedTasks: (state, action) => {
            state = {
                ...state,
                failedTasksLoader: action.payload
            }
            return state;
        },
        loaderTasksHistory: (state, action) => {
            state = {
                ...state,
                tasksHistoryLoader: action.payload
            }
            return state;
        },
        loader_Rsapif: (state, action) => {
            state = {
                ...state,
                reportedSoldAndPaidInFullLoader: action.payload
            }
            return state;
        },
        loader_Rpftr: (state, action) => {
            state = {
                ...state,
                releasePaymentsForTRLoader: action.payload
            }
            return state;
        },
        loaderVaultedTitles: (state, action) => {
            state = {
                ...state,
                vaultedTitlesLoader: action.payload
            }
            return state;
        },
        setFailedTasksIssuesList: (state, action) => {
            state = {
                ...state,
                failedTasksIssuesList: action.payload
            }
            return state;
        },
        lmsPortalactionsUpdateInventory: (state, action) => {
            state = {
                ...state,
                inventorySearch: action.payload
            }
            return state;
        },
        lmsPortalactionsSetVin: (state, action) => {
            state = {
                ...state,
                vinSearchTextForInventorySearch: action.payload
            }
            return state;
        },
        lmsPortalActionSetLoader: (state, action) => {
            state = {
                ...state,
                inventorySearchLoader: action.payload
            }
            return state;
        },
        lmsPortalactionSetInventory: (state, action) => {
            state = {
                ...state,
                inventorySearch: action.payload.inventorySearch,
                inventoryCounts: action.payload.inventoryCounts
            }
            return state;
        },
        setBankHolidays: (state, action) => {
            state = {
                ...state,
                bankHolidaysList: action.payload.bankHolidaysList,
                bankHolidys_loader: action.payload?.loader ?? false,
                bankHolidysDataExists: action?.payload?.dataExists
            }
            return state;
        },
        setInitialBankHolidays: (state, action) => {
            state = {
                ...state,
                initialbankHolidays: action.payload
            }
            return state;
        },
        setTaskListDataExists: (state, action) => {
            state = {
                ...state,
                tasksListDataExists: action.payload
            }
            return state;
        },
        set_RsapifExists: (state, action) => { 
            state = {
                ...state,
                reportedSoldAndPaidInFullExists: action.payload
            }
            return state;
        },
        set_RpftrExists: (state, action) => {
            state = {
                ...state,
                releasePaymentsForTRExists: action.payload
            }
            return state;
        },
        setLeverTasksListExists: (state, action) => {
            state = {
                ...state,
                leverTasksListExists: action.payload
            }
            return state;
        }
    }
})

export const { setServicingCounts, setTasksListandCounts, setBankHolidays, setInitialBankHolidays, setLeverTasksList, setFailedTasks, tasksHistoryAdvancedFilters, setInitialTasksHistory, setTasksHistoryvinText, setTasksHistory,
    set_Rsapif, set_RsapifCounts, set_Rpftr, set_RpftrCounts, setVaultedTitles, loaderTasksList, loaderFailedTasks, loaderLeverTasksList, loaderTasksHistory, loader_Rsapif, loader_Rpftr, loaderVaultedTitles, setFailedTasksIssuesList, lmsPortalactionsUpdateInventory, lmsPortalactionsSetVin, lmsPortalActionSetLoader, lmsPortalactionSetInventory, setTaskListDataExists, set_RsapifExists, set_RpftrExists, setLeverTasksListExists } = Servicing.actions;

export default Servicing.reducer;