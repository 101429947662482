import { createSlice } from "@reduxjs/toolkit"

const defaultState = {
    auctionSuppliers: [],
    lienHolderSuppliers: [],
    floorPlanSuppliers: [],
    franchiseSuppliers: [],
    OEMSuppliers: [],
    auctionSuppliersLoader: false,
    lienHolderSuppliersLoader: false,
    floorPlanSuppliersloader: false,
    franchiseSuppliersloader: false,
    OEMSuppliersloader: false,
    supplierCounts: {},
    supplierAllTabsCount: {},
    defaultSupplierFilters:{}
}


const suppliersReducer = createSlice(({
    name: 'suppliers',
    initialState: defaultState,
    reducers: {
        setSupplierLoader: (state, action) => {
            state = {
                ...state,
                [action.payload.type]: action?.payload?.loaderStatus,
            }
            return state
        },
        setSupplierData: (state, action) => {
            state = {
                ...state,
                [action.payload.type]: action?.payload?.data?.length > 0 ? action.payload.data : [],
                supplierAllTabsCount : {
                    ...state.supplierAllTabsCount,
                    [action.payload.type] : action.payload.count  
                }
            }
            return state
        },
        updateSupplierData: (state, action) => {
            state = {
                ...state,
                [action.payload.type]: action.payload.data,
            }
            return state
        },
        setSupplierCounts: (state, action) => {
            state = {
                ...state,
                supplierCounts: action.payload,
            }
            return state
        },
        setDefaultSupplierFilters: (state, action) => {
            let keyName; let valueRecived;
            switch(action.payload.type){
                case 'Lien Holder' : keyName = 'lienHolderFilter'; valueRecived = action.payload.data;
                break;
                case 'Auction' : keyName = 'auctionFilter'; valueRecived = action.payload.data;
                break;
                case 'Floor Plan Company' : keyName = 'floorPlanCompany'; valueRecived = action.payload.data;
                break;
                case 'Franchise' : keyName = 'franchise'; valueRecived = action.payload.data;
                break;
                case 'OEM' : keyName = 'OEM'; valueRecived = action.payload.data;
                break;
                default:
                    return state;
            }   
            state = {
                ...state,
                defaultSupplierFilters: {
                    ...state.defaultSupplierFilters,
                    [keyName] : valueRecived
                }
            }
            return state
        }
    }

}))

export const { setSupplierData, setSupplierLoader, updateSupplierData, setSupplierCounts, setDefaultSupplierFilters } = suppliersReducer.actions

export default suppliersReducer.reducer;