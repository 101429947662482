import callAPI from '../../commonservices/APIRequests'
import { getLoanTypes } from '../../commonservices/CommonMethods';
import { setDealerFeatures, setSopraDealerList, setSopraSuppliersList, setCommonAdvancedFiltersList, setSupplierAssetTypes } from './Shared';

export const getSopraDealers = () => {


    return dispatch => {
        dispatch({ type: "ISSOPRADEALERSLOADED", isSopraDealersLoaded: true });
        return callAPI.get('/sopra/api/v1/sopra/getSopraDealers', {})
            .then((response) => {

                if (response.status === 200) {

                    dispatch(setSopraDealerList(
                        response.data.dealers && response.data.dealers.length > 0 ? response.data.dealers.filter(item1 => item1.dealerId !== null).map(item => {
                            return ({
                                label: item.referenceName,
                                value: item.reference,
                                id: item.dealerId,
                                sopraId: item.id
                            })
                        }) : []
                    ))

                }
                else {
                    dispatch(setSopraDealerList([]))
                }

            })

    }

}

export const getSopraSuppliers = () => {


    return dispatch => {
        return callAPI.get('/sopra/api/v1/sopra/getSopraSuppliers', {})
            .then((response) => {

                if (response.status === 200) {

                    dispatch(setSopraSuppliersList(
                        response.data.suppliers && response.data.suppliers.length > 0 ? response.data.suppliers.map(item => {
                            return ({
                                label: item.referenceName,
                                value: item.reference
                            })
                        }) : []
                    ))

                }
                else {
                    dispatch(setSopraSuppliersList([]))
                }

            })

    }

}

export const getCommonAdvancedFilters = (sharedReducerData) => {


    return dispatch => {
        return callAPI.get('/lender/api/v1/lender/filtersForlenderInventory', {})
            .then((response) => {

                if (response.status === 200) {

                    if (response.data.liveInventory) {

                        response.data.liveInventory.vehicles && response.data.liveInventory.vehicles.length > 0 && response.data.liveInventory.vehicles.forEach(item => {
                            item.label = item.vehicleName + ' (' + item.vin.substring(item.vin.length - 6) + ')'
                            item.value = item.vehicleId;
                        })

                        response.data.liveInventory.titleStatus && response.data.liveInventory.titleStatus.length > 0 && response.data.liveInventory.titleStatus.forEach(item => {
                            item.label = item.titleStatus;
                            item.value = item.titleStatus;
                        })

                        response.data.liveInventory.loanStatus && response.data.liveInventory.loanStatus.length > 0 && response.data.liveInventory.loanStatus.forEach(item => {
                            item.label = item.fundingStatus;
                            item.value = item.fundingStatus;
                        })

                        response.data.liveInventory.loanStatus && response.data.liveInventory.ddiTitleStatus.length > 0 && response.data.liveInventory.ddiTitleStatus.forEach(item => {
                            item.label = item.ddiTitleStatus;
                            item.value = item.ddiTitleStatus;
                        })

                        response.data.liveInventory.vehicleStatus && response.data.liveInventory.vehicleStatus.length > 0 && response.data.liveInventory.vehicleStatus.forEach(item => {
                            item.label = item.vehicleStatus;
                            item.value = item.vehicleStatus;
                        })

                        response.data.liveInventory.fundingRequestTypes = response.data.liveInventory.fundingRequestTypes && response.data.liveInventory.fundingRequestTypes.length > 0 && response.data.liveInventory.fundingRequestTypes.map(item => {
                            return {
                                label: getLoanTypes(sharedReducerData, item),
                                value: item
                            }
                        })
                    }

                    if (response.data?.inventoryHistory) {

                        response.data.inventoryHistory.vehicles && response.data.inventoryHistory.vehicles.length > 0 && response.data.inventoryHistory.vehicles.forEach(item => {
                            item.label = item.vehicleName + ' (' + item.vin.substring(item.vin.length - 6) + ')';
                            item.value = item.vehicleId;
                        })

                        response.data.inventoryHistory.titleStatus && response.data.inventoryHistory.titleStatus.length > 0 && response.data.inventoryHistory.titleStatus.forEach(item => {
                            item.label = item.titleStatus;
                            item.value = item.titleStatus;
                        })

                        response.data.inventoryHistory.loanStatus && response.data.inventoryHistory.loanStatus.length > 0 && response.data.inventoryHistory.loanStatus.forEach(item => {
                            item.label = item.fundingStatus;
                            item.value = item.fundingStatus;
                        })

                        response.data.inventoryHistory.ddiTitleStatus && response.data.inventoryHistory.ddiTitleStatus.length > 0 && response.data.inventoryHistory.ddiTitleStatus.forEach(item => {
                            item.label = item.ddiTitleStatus;
                            item.value = item.ddiTitleStatus;
                        })

                        response.data.inventoryHistory.fundingRequestTypes = response.data.inventoryHistory.fundingRequestTypes && response.data.inventoryHistory.fundingRequestTypes.length > 0 && response.data.inventoryHistory.fundingRequestTypes.map(item => {
                            return {
                                label: getLoanTypes(sharedReducerData, item),
                                value: item
                            }
                        })
                    }

                    dispatch(setCommonAdvancedFiltersList(response.data))

                }
                else {
                    dispatch(setCommonAdvancedFiltersList({}))
                }
            })

    }

}

export const getDealerFeatures = (supplierRef = '', dealer_id) => {
    return dispatch => {
        let req = {};
        if (supplierRef) {
            req.supplierReference = supplierRef
        } else if (dealer_id) {
            req.dealer_id = dealer_id
        }
        // dispatch({ type: "DEALERFEATURES", dealerFeatures: {} });
        return callAPI.get('/lender/api/v1/dealer/getDealerCustomFeatures', req)
            .then((response) => {

                if (response.status === 200) {

                    dispatch(setDealerFeatures(response.data.customFeatures));

                }
                else {
                    dispatch(setDealerFeatures({}));

                }

            })

    }

}

export const getSupplierAssetTypes = () => {

    return dispatch => {

        return callAPI.post('/lender/api/v1/dealer/getAuctionAssets')
            .then((response) => {

                if (response.status === 200) {

                    dispatch(setSupplierAssetTypes(response.data.assetTypes));

                }
                else {
                    dispatch(setSupplierAssetTypes([]));

                }

            })

    }

}